import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useAuth } from "./Auth";
import ReactGA from "react-ga4";

const HeaderElement = styled.header`
  background: #303440;
  display: flex;
  justify-content: center;

  .header-container {
    max-width: 1164px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media (max-width: 750px) {
      flex-direction: column;
    }
  }

  .branding {
    padding-left: 40px;

    @media (max-width: 750px) {
      padding-left: unset;
    }

    a {
      img {
        max-width: 265px;
        width: 100%;
      }
    }
  }

  .nav-container {
    display: flex;
    flex-direction: column;

    @media (max-width: 750px) {
      flex-direction: row;
    }

    nav.secondary-nav {
      position: absolute;
      top: 0;
      right: 0;

      @media (max-width: 750px) {
        display: none;
      }

      ul {
        display: flex;

        li {
          transform: skewX(40deg);
          transform-origin: bottom left;

          &.cmp-btn-blue {
            background: #0c66a5;
          }

          &.cmp-btn-grey {
            background: #607588;
          }

          &.cmp-btn-orange {
            background: #dc6126;
          }

          &:not(:last-child) {
            margin-right: 3px;
          }

          a,
          div {
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
            letter-spacing: 0.42px;
            color: #fff;
            text-transform: uppercase;
            padding: 7px 30px;
            display: inline-block;
            transform: skewX(-40deg);
            text-decoration: none;
            position: relative;
          }

          &:last-child {
            &.cmp-btn-blue {
              a,
              div {
                &::after {
                  position: absolute;
                  content: " ";
                  background: #0c66a5;
                  top: 0px;
                  bottom: 0;
                  right: -12px;
                  z-index: -1;
                  height: 100%;
                  width: 50px;
                  display: inline-block;
                }
              }
            }

            &.cmp-btn-grey {
              a,
              div {
                &::after {
                  position: absolute;
                  content: " ";
                  background: #607588;
                  top: 0px;
                  bottom: 0;
                  right: -12px;
                  z-index: -1;
                  height: 100%;
                  width: 50px;
                  display: inline-block;
                }
              }
            }

            &.cmp-btn-orange {
              a,
              div {
                &::after {
                  position: absolute;
                  content: " ";
                  background: #dc6126;
                  top: 0px;
                  bottom: 0;
                  right: -12px;
                  z-index: -1;
                  height: 100%;
                  width: 50px;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }

    nav.primary-nav {
      margin-top: 38px;
      margin-right: 16px;
      padding: 26px 0;

      @media (max-width: 1230px) {
        margin-right: 54px;
      }

      @media (max-width: 750px) {
        margin-top: unset;
        margin-right: unset;
      }

      & > ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        li {
          &:not(:last-child) {
            margin-right: 20px;
          }

          img {
            max-width: 30px;
            height: auto;
          }

          div.cmp-header-profile {
            position: relative;

            &:hover {
              cursor: pointer;
            }

            .account-dropdown {
              position: absolute;
              width: 200px;
              background: #fff;
              box-shadow: 0px 3px 6px #0000001f;
              right: -34px;
              top: 50px;
              z-index: 999;

              &::before {
                content: " ";
                position: absolute;
                top: 3px;
                right: 24px;
                border-bottom: 17px solid white;
                border-left: 18px solid transparent;
                border-right: 20px solid transparent;
                margin-right: 4px;
                margin-top: -17px;
                z-index: 999999;
              }

              ul {
                li {
                  width: 100%;

                  a {
                    text-decoration: none;
                    color: #337ab7;
                    font-weight: 700;
                    display: block;
                    padding: 16px;
                  }
                }
              }
            }
          }

          div.welcome-video-btn {
            color: #fff;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .mobile-nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #303440;
    z-index: 999;

    div.close-mobile-menu {
      position: absolute;
      right: 20px;
      top: 20px;
      background: #ffffff;
      width: 44px;
      height: 44px;
      border-radius: 50px;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 13px;
        height: 13px;
      }
    }

    nav {
      margin-top: 20%;

      ul {
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
          min-width: 240px;
          display: flex;

          a {
            font-size: 18px;
            line-height: 21px;
            font-weight: 700;
            letter-spacing: 0.54px;
            color: #ffffff;
            text-transform: uppercase;
            text-decoration: none;

            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: solid 2px #607588;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  .open-mobile-nav {
    position: absolute;
    right: 20px;
    top: 20px;
    background: #dc6126;
    width: 44px;
    height: 44px;
    border-radius: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;

    @media (min-width: 751px) {
      display: none;
    }

    img {
      width: 18px;
      height: 14px;
    }
  }
`;

const Header = () => {
  const { authenticated, cmpData, environment, setWelcomeModal, cuesURL, cmanURL, acgiURL } = useAuth();

  const logo = require("./assets/images/logo-mycues-dashboard.png");
  const iconCart = require("./assets/images/icn-cart.png");
  const iconProfile = require("./assets/images/icn-profile.png");
  const iconMenuOpen = require("./assets/images/icn-menu-white.png");
  const iconMenuClose = require("./assets/images/icn-close-dark.png");

  const [showMobileNav, setShowMobileNav] = useState(false);
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);

  function handleGAEvent(eventTitle) {
    if (cmpData) {
      if (window.location.host == "mycues.cues.org") {
        ReactGA.event(eventTitle, {
          user_role: cmpData.account_info.acgiRole,
          user_memberlevel: cmpData.account_info.membershipLevel,
          user_company: cmpData.account_info.employer,
        });
      }
    }
  }

  return (
    <HeaderElement>
      <div className="header-container">
        <div className="branding">
          <a href="/">
            <img src={logo} alt="myCUES Logo" />
          </a>
        </div>

        <div className="nav-container">
          {/* Secondary Nav */}
          <nav className="secondary-nav">
            <ul>
              <li className="">
                <a
                  className={"cmp-header-link"}
                  href={cuesURL + '/member-help-center'}
                  target="_blank"
                  onClick={() => {
                    handleGAEvent("header_memberhelpcenter");
                  }}
                >
                  Member Help Center
                </a>
              </li>
              <li className="cmp-btn-blue">
                <a
                  className={"cmp-header-link"}
                  href={cuesURL + "/cumanagement-external"}
                  target="_blank"
                  onClick={() => {
                    handleGAEvent("header_cumanagement");
                  }}
                >
                  CUMANAGEMENT.COM
                </a>
              </li>
              <li className="cmp-btn-orange">
                <a
                  className={"cmp-header-link"}
                  href={cuesURL}
                  target="_blank"
                  onClick={() => {
                    handleGAEvent("header_cuesorg");
                  }}
                >
                  CUES.ORG
                </a>
              </li>
            </ul>
          </nav>

          {/* Primary Nav */}
          <nav className="primary-nav">
            <ul>
              {/* CUES Member Dashboard Link */}
              <li>
                <div
                  className="welcome-video-btn"
                  onClick={() => {
                    setWelcomeModal(true);
                  }}
                >
                  Get started with <strong>myCUES Dashboard</strong>
                </div>
              </li>
              {/* Cart Link */}
              <li>
                <a
                  className={"cmp-header-link--cart"}
                  href={acgiURL + "/f?p=ECSSA:15010:17861068631439"}
                  onClick={() => {
                    handleGAEvent("header_cart");
                  }}
                >
                  <img className="cmp-header-cart" src={iconCart} alt="Cart" />
                </a>
              </li>
              {/* Manage Account Link */}
              <li>
                <div className="cmp-header-profile">
                  <img
                    src={iconProfile}
                    alt="Profile"
                    onClick={() => {
                      setShowAccountDropdown(!showAccountDropdown);
                    }}
                  />

                  {showAccountDropdown && (
                    <div className="account-dropdown">
                      <ul>
                        <li>
                          <a
                            className={"cmp-header-link--logout"}
                            href={cuesURL + "/user/logout"}
                            onClick={() => {
                              handleGAEvent("header_logout");
                            }}
                          >
                            LOG OUT
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </li>
            </ul>
          </nav>
        </div>

        <div
          className="open-mobile-nav"
          onClick={() => {
            setShowMobileNav(true);
          }}
        >
          <img src={iconMenuOpen} alt="Open Mobile Menu" />
        </div>
      </div>

      {showMobileNav && (
        <div className="mobile-nav">
          <div
            className="close-mobile-menu"
            onClick={() => {
              setShowMobileNav(false);
            }}
          >
            <img src={iconMenuClose} alt="Close Mobile Menu" />
          </div>

          <nav>
            <ul>
              <li>
                <a href="https://www.cues.org/">CUES.ORG</a>
              </li>

              <li>
                <a href="https://www.cumanagement.com/">CUMANAGEMENT.COM</a>
              </li>

              <li>
                <a href="https://www.cues.org/member-help-center">MEMBER HELP CENTER</a>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </HeaderElement>
  );
};

export default Header;
